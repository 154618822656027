<template>
  <div class="card" style="height: 91%">
    <!--begin::Chart-->
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom mt-4"
      :options="options"
      :series="series"
      :height="280"
      type="pie"
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
/*eslint-disable */

export default {
  props: ["data"],
  data() {
    return {
      options: {},
      series: [],
    };
  },

  watch: {
    data: function (value) {
      this.updateChart();
    },
  },

  created() {
    this.updateChart();
  },

  methods: {
    updateChart() {
      const total = this.data.reduce((acc, level) => {
        let t = 0;
        t += level.gte16;
        t += level.between15And16;
        t += level.between10And14;
        t += level.lt10;
        return acc + t;
      }, 0);
      const hasMoyenne = this.data.reduce(
        (acc, level) => acc + level.hasMoyenne,
        0
      );

      const series = [total - hasMoyenne, hasMoyenne];
      const labels = [
        "مجموع الغيّر المتحصلين على معدل",
        "مجموع المتحصلين على معدل",
      ];

      this.updateOptions(labels);
      this.updateSeries(series);
    },

    updateSeries(series) {
      this.series = series;
    },
    updateOptions(labels) {
      this.options = {
        colors: ["#ff6178", "#26a0fc"],
        chart: {
          height: 300,
          type: "pie",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            export: {
              csv: {
                filename: "الإحصاء المدرسي",
                columnDelimiter: ",",
                headerCategory: "المسوى",
                headerValue: "value",
              },
              svg: {
                filename: "الإحصاء المدرسي",
              },
              png: {
                filename: "الإحصاء المدرسي",
              },
            },
            autoSelected: "zoom",
          },
        },
        legend: {
          position: "bottom",
        },
        labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "top",
              },
            },
          },
        ],
      };
    },
  },
};
</script>
