<template>
  <div class="card">
    <!--begin::Chart-->
    <apexchart
      class="statistics-widget-3-chart card-rounded-bottom"
      :options="options"
      :series="series"
      :height="280"
      type="bar"
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
/*eslint-disable */

export default {
  props: ["subject"],
  data() {
    return {
      options: {},
      series: [],
    };
  },

  watch: {
    subject: function (value) {
      this.updateChart();
    },
  },

  created() {
    this.updateChart();
  },

  methods: {
    updateChart() {
      const series = [
        {
          name: "يفوق 15",
          data: this.subject.levels.map((level) => level.subjectMoreThan15),
          color: "#26a0fc",
        },
        {
          name: "يفوق 10 إلى 15",
          data: this.subject.levels.map((level) => level.subjectBetween10And15),
          color: "#00e365",
        },
        {
          name: "ـ 10 من 20",
          data: this.subject.levels.map((level) => level.subjectEqual10),
          color: "#d0dd54",
        },
        {
          name: "من 5 الى دون 10 من 20",
          data: this.subject.levels.map((level) => level.subjectBetween5And10),
          color: "#feb019",
        },
        {
          name: "دون 5 من 20",
          data: this.subject.levels.map((level) => level.subjectLessThan5),
          color: "#ff6178",
        },
      ];
      const levels = this.subject.levels.map((level) => level.level + "س");

      // get max student number for each level
      const students = this.subject.levels.map((level) => level.students);

      this.updateOptions(levels, students);
      this.updateSeries(series);
    },

    updateSeries(serie) {
      this.series = serie;
    },
    updateOptions(types, students) {
      this.options = {
        chart: {
          fontFamily: "Helvetica, Arial, sans-serif",
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            export: {
              csv: {
                filename: this.subject.name,
                columnDelimiter: ",",
                headerCategory: "المسوى",
                headerValue: "value",
              },
              svg: {
                filename: this.subject.name,
              },
              png: {
                filename: this.subject.name,
              },
            },
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "90%",
            endingShape: "rounded",
            dataLabels: {
              position: "top",
            },
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        title: {
          text: this.subject.name,
          align: "middle",
        },
        xaxis: {
          type: "string",
          categories: types,
          labels: {
            style: {
              fontSize: "15px",
              fontWeight: 500,
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val ? val.toFixed(0) : 0;
              },
            },
            min: 0,
          },
        ],
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val, data) {
              console.log(data);
              return "تلميذ " + val + "/" + students[data.dataPointIndex];
            },
          },
        },
        markers: {
          strokeWidth: 1,
        },
      };
    },
  },
};
</script>
